.b2bRequestForBtnContainer {
  margin: 32px 0px 14px 0px;
  .btn {
    border-radius: 5px;
    width: 256px;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }
}

.b2bRequestForQuoteMobileBtn {
  .btn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    width: 100vw;
    height: 48px;
    border: 0;
    position: fixed;
    z-index: 100;
    bottom: 0;
  }
}

.b2bRfqFormContainer {
  padding: 12px 15px 5px 15px;
  .productsLabel {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin: 0;
  }

  .closeIcon {
    position: absolute;
    top: 26px;
    right: 26px;
    cursor: pointer;
  }

  .productName {
    font-size: 14px;
    font-weight: 400;
    color: #414141;
    max-width: 300px;
    margin: 0;
  }

  .variantName {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .removeBtn {
    margin-top: 2px;
    margin-left: 11px;
    background-color: #ffffff;
    color: #ff5555;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    width: 44px;
    cursor: pointer;
  }

  .minQuantityInputContainer {
    display: flex;
    flex-direction: column;
    max-width: 138px;

    .minQuantityLabel {
      font-size: 12px;
      font-weight: 400;
      margin: 4px 0 0 0;
      color: #999999;
    }
  }

  .addMoreProducts {
    font-size: 12px;
    font-weight: 400;
    color: #005bc6;
    margin-top: 16px;
    border: 0;
    margin-bottom: 32px;
    cursor: pointer;
    background-color: #ffffff;
    text-align: left;
    width: 120px;
  }

  .fillDetailsLabel {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin: 0;
    padding-bottom: 24px;
  }

  .preferredDateTimeLabel {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    padding-top: 8px;
    margin: 0 0 24px 0;
  }

  .rfqInput {
    margin-bottom: 24px;
  }

  .submitBtn {
    width: 77px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }

  .addItemsBtn {
    width: 127px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }
}

.b2bMobileRfqFormContainer {
  height: 75vh;

  .productsLabel {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin: 0;
  }

  .closeIcon {
    position: fixed;
    top: 26px;
    right: 26px;
    cursor: pointer;
  }

  .productName {
    font-size: 14px;
    font-weight: 400;
    color: #414141;
    margin: 0;
  }

  .variantName {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .removeBtn {
    margin-top: 2px;
    margin-left: 11px;
    background-color: #ffffff;
    color: #ff5555;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    width: 44px;
    cursor: pointer;
  }

  .minQuantityInputContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .minQuantityLabel {
      font-size: 12px;
      font-weight: 400;
      margin: 4px 0 0 0;
      color: #999999;
    }
  }

  .addMoreProducts {
    font-size: 12px;
    font-weight: 400;
    color: #005bc6;
    margin-top: 16px;
    border: 0;
    margin-bottom: 32px;
    cursor: pointer;
    background-color: #ffffff;
    text-align: left;
    width: 120px;
  }

  .fillDetailsLabel {
    font-size: 18px;
    font-weight: 700;
    color: #414141;
    margin: 0;
    padding-bottom: 24px;
  }

  .preferredDateTimeLabel {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    padding-top: 8px;
    margin: 0 0 24px 0;
  }

  .rfqInput {
    margin-bottom: 24px;
  }

  .submitBtn {
    width: 77px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }

  .addItemsBtn {
    width: 127px;
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }
}
